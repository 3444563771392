import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';

const StSpacing = styled.div.attrs(props => ({
    className: 'StSpacing',
}))`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: ${settings.bp.medium.view}) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    ${props =>
        props.removeSpaceTop &&
        css`
            padding-top: 0 !important;
        `};

    ${props =>
        props.removeSpaceBottom &&
        css`
            padding-bottom: 0 !important;
        `};

    ${props =>
        props.removeSpaceTopMobile &&
        css`
            @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                padding-top: 0;
            }
        `};

    ${props =>
        props.removeSpaceBottomMobile &&
        css`
            @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                padding-bottom: 0;
            }
        `};
    ${props =>
        props.flexSpacing &&
        css`
            display: block;
            @media (min-width: ${settings.bp.small.view}) {
                display: flex;
            }
        `};
    ${props =>
        props.backgroundImage &&
        css`
            background-image: url(${props => props.backgroundImage});
        `};
    ${props =>
        props.addHorizontalMargin &&
        css`

           @media (min-width: calc(${settings.bp.medium.view} - 1px)) {
            margin-left: 5%;
            margin-right: 5%;
        }
        `};
`;

export default function Spacing(props) {
    return <StSpacing {...props}>{props.children}</StSpacing>;
}
