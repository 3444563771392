import React from 'react';
import styled, { css } from 'styled-components';
import Container from './Container';
import Title from './atoms/Title';
import AllButton from './atoms/AllButton';
import settings from '../global/settings';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Side = styled.div`
    width: 100%;
    padding: 30px;

    background: url(${props => props.backgroundImage});
    &:nth-child(1) {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &:nth-child(2) {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    ${props =>
        !props.fullWidth &&
        css`
            @media (min-width: 768px) {
                width: 50%;
                padding: 50px;
            }
        `};

    ${props =>
        props.fullWidth &&
        css`
            width: 100%;
            padding: 0px;
        `};

    ${props =>
        props.smallImage &&
        css`
            display: flex;
            flex-direction: row;
            margin-left: 5%;
            margin-right: 5%;
            text-align: center;
            @media (max-width: ${settings.bp.small.view}) {
                flex-direction: column;
            }
        `};
`;

const StSmallImage = styled.div`
    display: flex;
    width: 50%;
    max-height: 80%;
    align-self: center;
    @media (max-width: ${settings.bp.small.view}) {
        width: 100%;
    }
`;

const Overlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${props =>
        props.overlayBackground &&
        css`
            background: rgba(255, 255, 255, 0.7);
        `};

    min-height: 323px;
    padding: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 620px;
        padding: 0px;
    }

    ${props =>
        props.fullWidth &&
        css`
            min-height: 250px;
            width: 50%;
            margin-left: auto;
            padding: 0px;
            padding-top: 0px;
            @media (min-width: ${settings.bp.small.view}) {
                min-height: 350px;
            }
            @media (min-width: ${settings.bp.medium.view}) {
                min-height: 500px;
            }
            @media (min-width: ${settings.bp.large.view}) {
                min-height: 550px;
            }
            @media (min-width: ${settings.bp.extraLarge.view}) {
                min-height: 700px;
            }
        `};

    ${props =>
        props.left &&
        css`
            margin-left: unset;
            width: 100%;
        `};

    ${props =>
        props.minimal &&
        css`
            min-height: 350px !important;
        `};
`;

const Subtitle = styled.h4`
    font-size: 28px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.8px;
`;
const Content = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    text-align: center;
    letter-spacing: 0.05em;
    max-width: 310px;
    margin-top: 24px;
    margin-bottom: 24px;
    @media (max-width: 767.08px) {
        a {
            margin-top: 0px;
        }
    }
    @media (min-width: 768px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
`;
const MdBreakPoint = '768px';

const RequestBtn = styled.a`
    width: 90%;
    min-height: 50px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    border: 0;
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: 24px;
    letter-spacing: 1px;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
    @media (min-width: ${MdBreakPoint}) {
        // margin-top: 48px;
    }
    @media (max-width: calc(${settings.bp.small.view} - 1px)) {
        font-size: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    ${props =>
        props.smallButton &&
        css`
            width: unset;
            display: unset;
            min-height: unset;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 36px;
            padding-right: 36px;
            background: transparent;
            color: #000000;
            border: solid 1px #000000;
            font-size: 16px;
            letter-spacing: 2px;
            font-weight: 500;
            &:hover {
                background: #00000010;
            }
        `};

        ${props =>
            props.mobileHide &&
            css`
            @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                display: none !important;
            }
        `};
`;

const Button = ({ children, href, smallButton }) => {
    return (
        <RequestBtn smallButton={smallButton} href={href}>
            {children}
        </RequestBtn>
    );
};

export default function FindYourPerfectProduct({
    items,
    title,
    smallButton,
    left,
    minimal,
}) {
    return (
        <section>
            <Wrapper>
                {items.map((item, index) => {
                    console.log('item', item);
                    return (
                        <Side
                            key={`side-quizz-` + index}
                            backgroundImage={item.backgroundImage}
                            fullWidth={item.fullWidth}
                            smallImage={item.smallImage}
                        >
                            <Overlay
                                left={left}
                                minimal={minimal}
                                overlayBackground={item.overlayBackground}
                                fullWidth={item.fullWidth}
                            >
                                <Subtitle>{item?.title}</Subtitle>
                                {!item.fullWidth && (
                                    <Content>{item?.text}</Content>
                                )}
                                <Button
                                    smallButton={smallButton}
                                    href={item?.url}
                                >
                                    {item?.btnText}
                                </Button>
                            </Overlay>
                            {item.smallImage && (
                                <StSmallImage>
                                    <img src={item.smallImage} />
                                </StSmallImage>
                            )}
                        </Side>
                    );
                })}
            </Wrapper>
        </section>
    );
}
