import React, { useState, useEffect, Fragment } from 'react'
import styled, { css } from 'styled-components'
import settings from '../../../kenra-storybook/global/settings'
import { useSwipeable } from 'react-swipeable'
import { SliderHero } from '../../../kenra-storybook/index'
import ProductsRow from './ProductsRow'
const {
    StSliderHeroImgWeb,
    StSliderHeroImgMobile,
} = SliderHero


const StCollectionTitle = styled.div`
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 115%;
letter-spacing: 2px;
text-transform: uppercase;
text-align: center;

    @media (min-width: ${settings.bp.small.view}) {
      font-size: 32px;
    }
`

export const parseFragments = (fragments, intl) => {
    let fragmentDataMap = fragments.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value
                    break
                case 'Title':
                    returnData['title'] = fragmentData.value
                    break
                case 'Text':
                    returnData['text'] = fragmentData.value
                    if (fragmentData.value !== null) {
                        returnData['text'] = fragmentData.value.replace(
                            '\\n',
                            '\n '
                        )
                    }
                    break
                default:
                    break
            }
        })
        return {
            image: returnData.img,
            title: returnData.title,
            text: returnData.text,
        }
    })

    return fragmentDataMap
}

const StTestimonalsContainer = styled.div`
    max-width: 100%;
    padding-top: 24px;
    margin: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        max-width: 100%;
        padding-top: 44px;
        padding-bottom: 44px;
        background-color: white;
    }
`



export const Benefits = props => {
    const {
        benefits,
    } = props
    const maxIndex = benefits.length - 1
    const [index, setIndex] = useState(0)
    const [autoRotate, setAutoRotate] = useState(true)
    const [mousePosition, setMousePosition] = useState(0)

    const mouseMoveHandler = event => {
        const { width } = event.target.getBoundingClientRect()
        const percentage =
            ((event.clientX - event.target.offsetLeft) / width) * 100
        setMousePosition(percentage)
    }

    useEffect(() => {
        window.addEventListener('mousemove', mouseMoveHandler)
        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler)
        }
    }, [])

    const config = {
        delta: 10,
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0, // set a rotation angle
        swipeDuration: 250, // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    }

    const swipeHandler = useSwipeable({
        onSwiped: eventData => {
            if (autoRotate) {
                setAutoRotate(false)
            }
            if (eventData.dir === 'Left' && eventData.deltaX < -50) {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            } else if (eventData.dir === 'Right' && eventData.deltaX > 50) {
                let newIndex = index - 1 < 0 ? maxIndex : index - 1
                setIndex(newIndex)
            }
        },
        ...config,
    })

    useEffect(() => {
        if (autoRotate) {
            const interval = setInterval(() => {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            }, 3500)
            return () => clearInterval(interval)
        }
    }, [index, autoRotate])

    if (!benefits) {
        return null
    }

    return (


        <StSliderHeroImgWeb style={{ margin: 'auto', maxWidth: '90%', alignSelf: 'center' }}>


            {benefits && <StTestimonalsContainer
                onClick={() => {
                    if (autoRotate) {
                        setAutoRotate(false)
                    }
                    if (mousePosition >= 50) {
                        let newIndex = index + 1 > maxIndex ? 0 : index + 1
                        setIndex(newIndex)
                    } else {
                        let newIndex = index - 1 < 0 ? maxIndex : index - 1
                        setIndex(newIndex)
                    }
                }}
                {...swipeHandler}
            >
                <div
                    style={{
                        flex: 1,
                        marginBottom: '12px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    key={`testimonial_${index}`}
                >
                    <img
                        src={benefits[index]?.image?.localFile?.publicURL}
                    />
                    <div style={{ textAlign: 'center' }}>
                        {benefits.map((t, tIndex) => {
                            return (
                                <span
                                    key={`dot-${tIndex}`}
                                    style={
                                        index === tIndex
                                            ? {
                                                backgroundColor: '#000',
                                                height: '7px',
                                                width: '7px',
                                                borderRadius: '50%',
                                                display: 'inline-block',
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                            }
                                            : {
                                                height: '7px',
                                                width: '7px',
                                                backgroundColor: '#D9D9D9',
                                                borderRadius: '50%',
                                                display: 'inline-block',
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                            }
                                    }
                                />
                            )
                        })}
                    </div>
                </div>

            </StTestimonalsContainer>}
        </StSliderHeroImgWeb>



    )
}

export const MobileBenefits = props => {
    const {
        benefits,
    } = props
    const maxIndex = benefits.length - 1
    const [index, setIndex] = useState(0)
    const [autoRotate, setAutoRotate] = useState(true)
    const [mousePosition, setMousePosition] = useState(0)

    const mouseMoveHandler = event => {
        const { width } = event.target.getBoundingClientRect()
        const percentage =
            ((event.clientX - event.target.offsetLeft) / width) * 100
        setMousePosition(percentage)
    }

    useEffect(() => {
        window.addEventListener('mousemove', mouseMoveHandler)
        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler)
        }
    }, [])

    const config = {
        delta: 10,
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0, // set a rotation angle
        swipeDuration: 250, // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    }

    const swipeHandler = useSwipeable({
        onSwiped: eventData => {
            if (autoRotate) {
                setAutoRotate(false)
            }
            if (eventData.dir === 'Left' && eventData.deltaX < -50) {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            } else if (eventData.dir === 'Right' && eventData.deltaX > 50) {
                let newIndex = index - 1 < 0 ? maxIndex : index - 1
                setIndex(newIndex)
            }
        },
        ...config,
    })

    useEffect(() => {
        if (autoRotate) {
            const interval = setInterval(() => {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            }, 3500)
            return () => clearInterval(interval)
        }
    }, [index, autoRotate])

    if (!benefits) {
        return null
    }

    return (

        <StSliderHeroImgMobile>
            {benefits && <StTestimonalsContainer
                onClick={() => {
                    if (autoRotate) {
                        setAutoRotate(false)
                    }
                    if (mousePosition >= 50) {
                        let newIndex = index + 1 > maxIndex ? 0 : index + 1
                        setIndex(newIndex)
                    } else {
                        let newIndex = index - 1 < 0 ? maxIndex : index - 1
                        setIndex(newIndex)
                    }
                }}
                {...swipeHandler}
            >
                <div
                    style={{
                        flex: 1,
                        marginBottom: '12px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    key={`testimonial_${index}`}
                >
                    <img
                        src={benefits[index]?.mobileImage?.localFile?.publicURL}
                    />
                    <div style={{ textAlign: 'center', marginTop: '-20px' }}>
                        {benefits.map((t, tIndex) => {
                            return (
                                <span
                                    key={`dot-${tIndex}`}
                                    style={
                                        index === tIndex
                                            ? {
                                                backgroundColor: '#000',
                                                height: '7px',
                                                width: '7px',
                                                borderRadius: '50%',
                                                display: 'inline-block',
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                            }
                                            : {
                                                height: '7px',
                                                width: '7px',
                                                backgroundColor: '#B3B3B3',
                                                borderRadius: '50%',
                                                display: 'inline-block',
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                            }
                                    }
                                />
                            )
                        })}
                    </div>
                </div>

            </StTestimonalsContainer>}
        </StSliderHeroImgMobile>

    )
}



export const CollectionSection = props => {
    const {
        benefits,
        title,
        products,
        allShopifyProducts,
        allGoogleSheetProductsRow,
        isPro,
    } = props

    if (!benefits && !products) {
        return null
    }

    return (
        <>
            {title && <StCollectionTitle>
                {title}
            </StCollectionTitle>}
            {benefits && <MobileBenefits
                benefits={benefits}
            />}
            {products &&
                <ProductsRow
                    products={products}
                    shopifyProducts={allShopifyProducts}
                    allGoogleSheetProductsRow={allGoogleSheetProductsRow}
                    magicMousse={true}
                    isPro={isPro}
                />
            }
            {benefits && <Benefits
                benefits={benefits}
            />

            }

        </>
    )
}