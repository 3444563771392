export function getProductUrl(handle) {
    return `/product/${handle}`;
}

export function getCollectionUrl(handle) {
    return `/collection/${handle}`;
}

export function getVideoUrl(slug) {
    return `/video/${slug}`;
}

export function isUrlInDomain(url) {
    const domains = [
        'kenraprofessional.com',
        'kenra-pro.matchboxmobile.com',
        'localhost:8000',
    ];
    let domainFound = false;
    domains.every(domain => {
        if (url.includes(domain)) {
            domainFound = true;
            return false;
        }
        return true;
    });

    if (domainFound) {
        return true;
    }
    return false;
}

export default {
    contact: '/contact',
    quiz: '/quiz',
    carryUs: '/carry-us',
    videos: '/video',
    faq: '/faq',
    blog: '/blog',
    aboutUs: '/about-us',
    tools: '/tools',
    applications: '/applications',
    privacy:
        'https://www.henkel-northamerica.com/privacy-statement-na?view=content-blank',
    privacyfr:
        'https://www.henkel-northamerica.com/privacy-statement-ca?view=content-blank',
    termsOfUse:
        'https://www.henkel-northamerica.com/terms-of-use-na?view=content-blank',
    termsOfUsefr:
        'https://www.henkel-northamerica.com/terms-of-use-ca?view=content-blank',
    henkelImprint: 'https://www.henkel.com/imprint',
    accessibility: '/accessibility',
    colorList: '/color-list',
    submitted: '/submitted',
    salonRequestSubmitted: '/in-salon-request-submitted',
    eventList: '/event-list',
    educatorApplication: '/educator-application',
    joinTheTeam: '/join-the-team',
    caPrivacy: 'https://www.henkel-northamerica.com/ca-privacy-rights',
    dataProtection: '/data-protection',
    doNotSellInformation:
        'https://henkelprivacy.exterro.net/portal/dsar.htm?target=henkelprivacy',
    notFound: '/404',
};
