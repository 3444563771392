import React from 'react'
import get from 'lodash.get'
import styled, { css } from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import HeroSlider from 'components/HeroSlider'
import BestSellerSlider from 'components/NewHome/BestSellerSlider'
import { Spacing, SharedStyles, Container, SliderHero, SliderBestSellerItem, SliderBestSellerItemWrapper } from '../../../kenra-storybook/index'
import { MediaSnippets } from './MediaSnippets'
import { Testimonials } from './Testimonials'
import { CollectionSection } from './Benefits'
import settings from '../../../kenra-storybook/global/settings'
import { getProductUrl } from 'helpers/url'
const { StPageTitle } = SharedStyles
const {
  StSliderHeroImgWeb,
  StSliderHeroImgMobile,
} = SliderHero
import ProductsRow from './ProductsRow'
import ScrollingProductsRow from './ScrollingProductsRow'

export const StCollectionsTitle = styled.div`
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 115%;
letter-spacing: 2px;
text-transform: uppercase;
text-align: center;

    @media (min-width: ${settings.bp.small.view}) {
      font-size: 40px;
    }
`

const SHOPS = {
  ultaLink: { img: '/images/ULTA.png', title: 'Ulta', newTab: true },
  amazonLink: {
    img: '/images/Amazon.png',
    title: 'Amazon',
    newTab: true,
  },
  cosmoProfLink: {
    img: '/images/CosmoProf.png',
    title: 'CosmoProf',
    newTab: true,
  },
  salonCentricLink: {
    img: '/images/SalonCentric.png',
    title: 'SalonCentric',
    newTab: true,
  },
  salonoryLink: {
    img: '/images/salonory_wt.png',
    title: 'Salonory',
    newTab: true,
  },
}

const linkDictionary = {
  "2893708": {
    id: 2893708,
    title: "Triple Repair Shampoo",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/3WM5a6v",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/3SvLf9a",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/3SvLf9a",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CP8MNSL4?maas=maas_adg_FA805A5CE509BC5580F0044F721D847D_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/triple-repair-shampoo-bonding-damaged-hair-pimprod2042815?sku=2614448&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2893804": {
    id: 2893804,
    title: "Triple Repair Conditioner",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/4c6ff2A",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/3SxrKNs",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/4dqgA5f",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CP8MB4F1?maas=maas_adg_FD6AD98178ECF8D068A73BD78D046815_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/triple-repair-conditioner-bonding-damaged-hair-pimprod2042816?sku=2614450&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2893808": {
    id: 2893808,
    title: "Triple Repair Serum",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/3Ymzdmp",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/4c3emI5",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/4dmbxTp",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CP8M1NWK?maas=maas_adg_4DEC3ABCB6AEDBC444204450BBF92009_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/triple-repair-split-end-mending-serum-pimprod2042817?sku=2614496&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2928907": {
    id: 2928907,
    title: "Volume Shampoo",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/4dpPY4h",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/4dn8p9H",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/3ylBBiC",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CT6C56SR?maas=maas_adg_39ED3C729C071E581004811747F1F6F9_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/volume-shampoo-pimprod2044499?sku=2622430&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2918779": {
    id: 2918779,
    title: "Color Protecting Shampoo",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/3YqHxkU",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/4dh5pMo",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/3WlfKzG",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CT699878?maas=maas_adg_A84A77716D4BD33367FC49B7D0829C1C_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/color-protecting-shampoo-pimprod2044501?sku=2622448&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2918783": {
    id: 2918783,
    title: "Color Protecting Conditioner",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/3Wrwg1e",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/4d1BW9t",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/3LNZqmn",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CT69Z4QM?maas=maas_adg_346BE7FFA36D905F9992321889028CDB_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/color-protecting-conditioner-pimprod2044502?sku=2622451&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2918823": {
    id: 2918823,
    title: "Moisture Shampoo",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/3Sz7tay",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/4fq1YVo",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/4c2DO0g",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CT6D2TRP?maas=maas_adg_C840E86C54AA801ACEDF16405588A760_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/moisture-shampoo-pimprod2044532?sku=2622720&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2918829": {
    id: 2918829,
    title: "Moisture Conditioner",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/4drc9Hg",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/3WK707N",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/4dr1RqE",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CT6BFRZB?maas=maas_adg_4D54341251B854FE99988672DA5AA09E_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/moisture-conditioner-pimprod2044534?sku=2622722&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2918842": {
    id: 2918842,
    title: "Violet Shampoo",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/4cZjBts",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/3YyERSi",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/3LN7CDl",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CT6LNV2G?maas=maas_adg_2D2FC16E7CC73E18CC6A881990185EAB_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/violet-shampoo-pimprod2044533?sku=2622452&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2918790": {
    id: 2918790,
    title: "Violet Conditioner",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/3LOVJwK",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/3SADe2P",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/4d4xyGE",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CT6W8W9W?maas=maas_adg_73C36C176976AC03E68208FF6A6FBE12_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/violet-conditioner-pimprod2044535?sku=2622455&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2918793": {
    id: 2918793,
    title: "Clarify Shampoo",
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/46sWwwJ",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/3AhWs6U",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/3WmQ3im",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CT6TPGNJ?maas=maas_adg_693BBA2C5B6643D3D4FD451223BBFCCD_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/clarify-shampoo-pimprod2044536?sku=2622433&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ]
  },
  "2927428": {
    id: 2927428,
    title: 'Volume Conditioner',
    proLinks: [
      {
        img: SHOPS.salonoryLink.img,
        title: SHOPS.salonoryLink.title,
        href: "https://bit.ly/46s1cD8",
        newTab: true,
      }, {
        img: SHOPS.cosmoProfLink.img,
        title: SHOPS.cosmoProfLink.title,
        href: "https://bit.ly/4dICu43",
        newTab: true,
      },
      {
        img: SHOPS.salonCentricLink.img,
        title: SHOPS.salonCentricLink.title,
        href: "https://bit.ly/4fnOmtD",
        newTab: true,
      },
    ],
    consumerLinks: [
      {
        img: SHOPS.amazonLink.img,
        title: SHOPS.amazonLink.title,
        href: "https://www.amazon.com/dp/B0CT6B1R9P?maas=maas_adg_5CB8B42B13001FD5B86F81FA3F7354E8_afap_abs&ref_=aa_maas&tag=maas",
        newTab: true,
      },
      {
        img: SHOPS.ultaLink.img,
        title: SHOPS.ultaLink.title,
        href: "https://www.ulta.com/p/volume-conditioner-pimprod2044500?sku=2622454&cmpid=BV_kenraprofessional_meta_fy24wk27",
        newTab: true,
      }
    ],
  }
}


export const getDropDownItems = (productId, isPro) => {
  if (!linkDictionary[productId]) {
    console.log('no links for ', productId)
  }
  return isPro ? linkDictionary[productId]?.proLinks : linkDictionary[productId]?.consumerLinks || []
}


const GHMLanding = ({
  intl,
  page,
  allShopifyProducts,
  allGoogleSheetProductsRow,
  isPro,
}) => {
  const {
    stylistFavoritesProducts,
  } = page

  return (
    <>
      <Spacing removeSpaceTop removeSpaceBottom>
        <HeroSlider slides={page.banner} />
      </Spacing>

      {page?.tabs?.length > 0 && (
        <Spacing removeSpaceBottom>
          <MediaSnippets snippets={page.tabs} />
        </Spacing>
      )}

      <Spacing removeSpaceBottom>
        <StPageTitle style={{ marginBottom: '12px' }}>
          <StCollectionsTitle>
            {get(page, 'carouselTitle')}
          </StCollectionsTitle>
        </StPageTitle>
        {page.carouselSlides?.length > 0 && (
          <>
            <StSliderHeroImgWeb>
              <img
                src={
                  page.carouselSlides[0].image.localFile
                    .publicURL
                }
              />
            </StSliderHeroImgWeb>
            <StSliderHeroImgMobile>
              <img
                src={
                  page.carouselSlides[0].mobileImage.localFile
                    .publicURL
                }
              />
            </StSliderHeroImgMobile>
          </>
        )}
      </Spacing>

      {page?.section1Data?.fragments && (
        <Spacing removeSpaceBottom addHorizontalMargin>
          <StPageTitle style={{ marginBottom: '12px' }}>
            <StCollectionsTitle>
              {get(page, 'section1Title')}
            </StCollectionsTitle>
          </StPageTitle>
          <Testimonials data={page.section1Data.fragments} />
        </Spacing>
      )}

      <Spacing removeSpaceBottom>
        <StPageTitle style={{ marginBottom: '36px' }}>
          <StCollectionsTitle>
            {get(page, 'collectionTitle')}
          </StCollectionsTitle>
        </StPageTitle>

        <CollectionSection
          title={get(page, 'collection1title')}
          allShopifyProducts={allShopifyProducts}
          allGoogleSheetProductsRow={allGoogleSheetProductsRow}
          products={page.collection1Products}
          benefits={page.collection1Benefits}
          isPro={isPro}
        />
        <CollectionSection
          title={get(page, 'collection2Title')}
          allShopifyProducts={allShopifyProducts}
          allGoogleSheetProductsRow={allGoogleSheetProductsRow}
          products={page.collection2Products}
          benefits={page.collection2Benefits}
          isPro={isPro}
        />
        <CollectionSection
          title={get(page, 'collection3Title')}
          allShopifyProducts={allShopifyProducts}
          allGoogleSheetProductsRow={allGoogleSheetProductsRow}
          products={page.collection3Products}
          benefits={page.collection3Benefits}
          isPro={isPro}
        />
      </Spacing>

      {stylistFavoritesProducts && stylistFavoritesProducts.length > 0 && (
        <Spacing>
          <StPageTitle style={{ marginBottom: '0px' }}>
            <StCollectionsTitle>
              {get(page, 'stylistFavoritesTitle')}
            </StCollectionsTitle>
          </StPageTitle>
          <ScrollingProductsRow
            title={get(page, 'stylistFavoritesTitle')}
            products={page.stylistFavoritesProducts}
            shopifyProducts={allShopifyProducts}
            allGoogleSheetProductsRow={allGoogleSheetProductsRow}
            magicMousse={true}
            isPro={isPro}
            mobileHorizontal={true}
          />
        </Spacing>
      )}
    </>
  )
}

export default injectIntl(GHMLanding)
